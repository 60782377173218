import React from "react";

export default function ConfirmButton({ onClick }) {
  return (
    <div
      onClick={onClick}
      className="w-20 h-20 rounded-2xl border-green-500 border-[6px] text-white flex items-center justify-center text-6xl"
    >
      <img src="confirm.png" className="scale-125" />
    </div>
  );
}

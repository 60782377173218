import React, { useEffect, useState } from "react";
import KeyButton from "./components/KeyButton";
import CancelButton from "./components/CancelButton";
import ConfirmButton from "./components/ConfirmButton";
import useLocalState from "@phntms/use-local-state";

function App() {
  const [correctNumber, setCorrectNumber] = useState(
    Math.floor(Math.random() * 100 + 1)
  );
  const [numbers, setNumbers] = useState([0, 100]);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [gameState, setGameState] = useState(0);

  const [historyNumber, setHistoryNumber] = useState("");

  const [numberToVoiceListJson, setNumberToVoiceListJson] = useLocalState(
    "list",
    `{
  "1": "呵呵呵，太可怕了",
  "2": "中了嗎，是中了嗎",
  "3": "嘻嘻嘻，太可怕了",
  "4": "是4嗎？真的要4嗎？虛驚一場",
  "5": "",
  "6": "",
  "7": "嘻嘻嘻，太可怕了",
  "8": "",
  "9": "",
  "10": "",
  "11": "小義小義經常神來一句",
  "12": "真有勇氣， 你沒中，我可怎麼辦",
  "13": "壹三壹三，這一生能認識苓也夠囉",
  "14": "壹四壹肆，這一世追不到苓也認囉",
  "15": "怎麼辦，怎麼辦，會GG",
  "16": "回憶你這個老六，呸呸呸",
  "18": "一八一八，月事精發發發",
  "20": "愛0，愛0，月哥哥對00最深情",
  "21": "21 儒哥哥生日笑嘻嘻",
  "22": "章鱼餓餓，瑟瑟發抖了，拉霸完蛋了",
  "23": "怎麼辦，怎麼辦，太可怕了",
  "24": "嘻嘻嘻，虛驚一場",
  "25": "怎麼辦，怎麼辦，太可怕了",
  "26": "",
  "27": "27，愛妻，阿儒仔最愛妻",
  "28": "別中啊，沒有浪花",
  "29": "怎麼辦，怎麼辦，這是第我一次，也是我最後一次了",
  "30": "虛驚一場，沒中",
  "33": "我不知道怎麼謝了，",
  "34": "三世修的同船渡，今世修的同螢幕",
  "35": "怎麼辦，怎麼辦，太可怕了",
  "37": "怎麼辦，怎麼辦，太可怕了",
  "38": "好弱阿，怎麼都沒有對手",
  "39": "怎麼辦，怎麼辦，太可怕了",
  "40": "40而不惑，50知天命，",
  "50": "",
  "51": "",
  "53": "怎麼辦，怎麼辦，可是我忍不住 會很醜",
  "54": "我忍不住了，要開心",
  "55": "切切切切，這麼可怕，",
  "56": "",
  "57": "五柒五柒，給祺哥哥來個美妻",
  "58": "",
  "59": "切切切切，這麼可怕，",
  "60": "",
  "61": "切切切切，這麼可怕，",
  "62": "",
  "63": "",
  "64": "怎麼辦，怎麼辦",
  "65": "怎麼辦，怎麼辦，虛驚一場",
  "66": "怎麼辦，怎麼辦",
  "67": "",
  "68": "",
  "69": "哎呀呀，你想到哪去了，",
  "70": "切切切切，這麼可怕，",
  "71": "切切切切，這麼可怕，",
  "72": "",
  "73": "",
  "74": "怎麼辦，怎麼辦",
  "75": "怎麼辦，怎麼辦，虛驚一場",
  "76": "",
  "77": "祺祺哥哥壞的很，氣",
  "78": "機車儒危險了，GG",
  "79": "",
  "80": "",
  "81": "",
  "82": "怎麼辦，怎麼辦，眼淚不聽使喚",
  "83": "怎麼辦，怎麼辦",
  "84": "哈哈哈，我是幸運女孩",
  "85": "哈哈哈，我是天選之人",
  "86": "哈哈哈，是被顏值被誤認的演唱主播",
  "87": "哩今嘛勒罵啥米郎，切切切",
  "88": "88，88叫爸爸 只要我不尷尬",
  "89": "不聽話就打",
  "90": "",
  "91": "怎麼辦，怎麼辦 我忍不住",
  "92": "就愛妳，",
  "93": "",
  "94": "就是你啦，哈哈哈，中",
  "95": "救我，救我，啊啊，神仙難救",
  "96": "危險了，准備要GG",
  "97": "97，確定要選北吃，呵呵",
  "98": "走吧牽著苓苓的手一起幸福生活吧",
  "99": "和苓苓結婚生子幸福久久"
}
`
  );

  const [page, setPage] = useState("1");

  useEffect(() => {
    if (historyNumber.endsWith("0515")) {
      setPage("2");
      setHistoryNumber("");
    }
  }, [historyNumber]);

  useEffect(() => {
    speak("終極密碼");
  }, []);

  function setNumberRange(v) {
    setHistoryNumber(historyNumber + String(v));

    if (
      (currentNumber === null && v !== 0) ||
      (Number(String(currentNumber || 0) + String(v)) > 0 &&
        Number(String(currentNumber || 0) + String(v)) < 100)
    ) {
      setCurrentNumber(Number(String(currentNumber || 0) + String(v)));
    }
  }

  function caculateNumberRange() {
    let newNumbers = [];

    if (currentNumber === correctNumber) {
      setCurrentNumber(null);
      setGameState(1);
      speak("中", 1);
    } else {
      if (
        currentNumber !== null &&
        numbers[0] < currentNumber &&
        currentNumber < numbers[1]
      ) {
        if (currentNumber < correctNumber) {
          newNumbers = [currentNumber, numbers[1]];
          setNumbers([currentNumber, numbers[1]]);
        } else {
          newNumbers = [numbers[0], currentNumber];
          setNumbers([numbers[0], currentNumber]);
        }
        setCurrentNumber(null);

        let list = {};

        try {
          list = JSON.parse(numberToVoiceListJson);
        } catch (e) {}

        speak(list[currentNumber]);
        speak(`${newNumbers[0]} 到 ${newNumbers[1]}`);
      }
    }
  }

  function reset() {
    setCorrectNumber(Math.floor(Math.random() * 100 + 1));
    setNumbers([0, 100]);
    setCurrentNumber(null);
    setGameState(0);
  }

  function speak(text, volume) {
    const speech = new SpeechSynthesisUtterance(text);

    // Optional: Set voice properties
    speech.pitch = 1; // 音调
    speech.rate = 1; // 语速
    speech.volume = volume ? volume : 0.5; // 音量

    // 获取所有可用语音
    const voices = window.speechSynthesis.getVoices();

    // 查找 zh-TW 语音
    const zhTWVoice =
      voices.filter((voice) => voice.lang === "zh-TW")[2] ||
      voices.filter((voice) => voice.lang.startsWith("zh"))[2];

    if (zhTWVoice) {
      speech.voice = zhTWVoice;
    } else {
      console.warn("未找到 zh-TW 語音，使用默認語音");
    }

    // Speak the text
    window.speechSynthesis.speak(speech);
  }

  return page === "1" ? (
    <div className="w-screen h-screen bg-black flex flex-col items-center justify-between pt-20 pb-4">
      {gameState ? (
        <div className="text-yellow-300 text-[200px]">中</div>
      ) : (
        <>
          <div className="text-white text-7xl">
            {numbers[0]} 到 {numbers[1]}
          </div>
          <div className="text-white text-8xl">{currentNumber}</div>
        </>
      )}
      <div className="grid grid-cols-3 gap-10 p-4">
        <KeyButton value={1} onClick={setNumberRange} />
        <KeyButton value={2} onClick={setNumberRange} />
        <KeyButton value={3} onClick={setNumberRange} />
        <KeyButton value={4} onClick={setNumberRange} />
        <KeyButton value={5} onClick={setNumberRange} />
        <KeyButton value={6} onClick={setNumberRange} />
        <KeyButton value={7} onClick={setNumberRange} />
        <KeyButton value={8} onClick={setNumberRange} />
        <KeyButton value={9} onClick={setNumberRange} />
        <CancelButton
          onClick={() => {
            setCurrentNumber(null);
          }}
        />
        <KeyButton
          value={0}
          onClick={gameState === 0 ? setNumberRange : reset}
        />
        <ConfirmButton onClick={caculateNumberRange} />
      </div>
    </div>
  ) : (
    <div className="w-screen h-screen bg-black flex flex-col items-center justify-between gap-4 p-4">
      <button
        onClick={() => {
          setPage("1");
        }}
        className="bg-white"
      >
        返回
      </button>
      <textarea
        value={numberToVoiceListJson}
        onChange={(e) => {
          setNumberToVoiceListJson(e.target.value);
        }}
        className="w-full h-full"
      ></textarea>
    </div>
  );
}

export default App;

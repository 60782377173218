import React from "react";

export default function CancelButton({ onClick }) {
  return (
    <div
      onClick={onClick}
      className="w-20 h-20 rounded-2xl border-red-500 border-[6px] text-white flex items-center justify-center text-6xl"
    >
      X
    </div>
  );
}

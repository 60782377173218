import React from "react";

export default function KeyButton({ value, onClick }) {
  return (
    <div
      onClick={() => {
        onClick(value);
      }}
      className="w-20 h-20 rounded-full border-white border-[6px] text-white flex items-center justify-center text-6xl"
    >
      {value}
    </div>
  );
}
